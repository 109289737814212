import './webpack-imports';
// import './sentry';
import './polyfills';
import { registerCustomElements } from './custom-elements';
import createRoom from './components/room';
import dropdowns from './inits/dropdowns';
import masks from './inits/masks';
import datepicker from './inits/datepicker';
import favoritesChangeView from './inits/favorites-change-view';
import historyBack from './inits/history-back';
import innRequest from './inits/inn-request';
import audio from './inits/audio';
import './inits/catalog';
import './inits/ajax-pagination';
import './inits/scroll-restoration';
import '../modules/validator';
import { lazyload } from './inits/lazy-load';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');
    registerCustomElements();
    createRoom();
    dropdowns.init();
    masks.init();
    datepicker.init();
    favoritesChangeView.init();
    historyBack.init();
    innRequest.init();
    audio.init();
    lazyload.update();

    // Main Scene Hovers

    const mainSceneHoverImages = Array.from(document.querySelectorAll<HTMLElement>('[data-static-image]'));
    const mainSceneHoverLinks = Array.from(document.querySelectorAll<HTMLElement>('[data-static-name]'));

    mainSceneHoverLinks.forEach((link) => {
        link.addEventListener('mouseenter', () => {
            const name = link.dataset.staticName;

            mainSceneHoverImages.forEach((img) => {
                img.classList.add('is-hidden');
            });

            if (name) {
                const images = mainSceneHoverImages.filter((img) => img.dataset.staticImage === name);

                images.forEach((image) => {
                    image.classList.remove('is-hidden');
                });
            }
        });

        link.addEventListener('mouseleave', () => {
            mainSceneHoverImages.forEach((img) => {
                img.classList.add('is-hidden');
            });
        });
    });
});
